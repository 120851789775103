import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'
import { isArray } from 'lodash'

type InitialStateType = Res['sortedMails'] | null

const initialState: InitialStateType = {}

export const sortedMailsSlice = createSlice({
  initialState,
  name: 'sortedMails',
  reducers: {
    clearSortedMails(state) {
      return {}
    },
    setSortedMails(state, action: PayloadAction<Req['setSortedMails']>) {
      const { batchId, mailId } = action.payload
      if (state?.[batchId]) {
        return {
          ...state,
          [batchId]: [...new Set([...state[batchId], mailId])],
        }
      } else {
        return {
          ...state,
          [batchId]: [mailId],
        }
      }
    },
  },
})

export const sortedMailsActions = sortedMailsSlice.actions

export const useSortedMailsActions = () => {
  const dispatch = useDispatch()
  const setSortedMails = useCallback(
    (payload: Req['setSortedMails']) => {
      return dispatch(sortedMailsActions.setSortedMails(payload))
    },
    [dispatch],
  )
  const clearSortedMails = useCallback(() => {
    return dispatch(sortedMailsActions.clearSortedMails())
  }, [dispatch])
  return { clearSortedMails, setSortedMails }
}

const selectSortedMails = (state: StoreStateType) => state.sortedMails

export const useSortedMails = () => {
  const { clearSortedMails, setSortedMails } = useSortedMailsActions()
  const sortedMails = useSelector(selectSortedMails)

  return useMemo(
    () => ({ clearSortedMails, setSortedMails, sortedMails }),
    [setSortedMails, sortedMails, clearSortedMails],
  )
}
