import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import cognito from 'common/cognito'
import { getApi } from 'common/api'
import { service } from 'common/service'
import dayjs from 'dayjs'
import toFormData from 'common/toFormData'
import { setToken } from './useToken'
import { getStore } from 'common/store'
import { Project } from 'common/project'
import { fromParam } from 'common/utils/urls/param'
import { sortedMailsActions } from './useSortedMails'

export const userService = service
  .enhanceEndpoints({ addTagTypes: ['User'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUser: builder.query<Res['user'], Req['getUser']>({
        providesTags: ['User'],
        queryFn: async (args, _, _2, baseQuery) => {
          const query = fromParam()
          if (query.token && query.refresh_token) {
            const data = {
              refresh_token: query.refresh_token,
              token: query.token,
            }
            debugger
            await setToken(data)
            return { data }
          }

          const data = getStore().getState().token
          return { data }
        },
      }),

      login: builder.mutation<Res['login'], Req['login']>({
        query: (query: Req['login']) => ({
          body: query,
          method: 'post',
          url: `${Project.apiAuth}auth/admins/login`,
        }),
        transformResponse: async (res) => {
          if (res.token) {
            await setToken(res)
          }
          await new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(null)
            }, 500)
          })
          return res
        },
        // queryFn: async (req) => {
        //   const data = {
        //     refresh_token:
        //       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcnkiOjEyMywic3ViIjoiMTIzNDU2Nzg5MCIsIm5hbWUiOiJKb2huIERvZSIsImlhdCI6MTUxNjIzOTAyMn0.fVyS-G6OzgH1J4_qv6ocuyIsg1vM2GEMzgWWJ-KoEKU',
        //     token:
        //       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcnkiOjEyMywic3ViIjoiMTIzNDU2Nzg5MCIsIm5hbWUiOiJKb2huIERvZSIsImlhdCI6MTUxNjIzOTAyMn0.fVyS-G6OzgH1J4_qv6ocuyIsg1vM2GEMzgWWJ-KoEKU',
        //     user: {
        //       email: 'kyle.johnson@solidstategroup.com',
        //       first_name: 'Kyle',
        //       last_name: '',
        //     },
        //   }
        //   setToken(data)
        //   return { data }
        // },
      }),

      logout: builder.mutation<{}, Req['logout']>({
        queryFn: async ({}, { dispatch }) => {
          await dispatch(service.util.resetApiState())
          await dispatch(sortedMailsActions.clearSortedMails())
          return { data: {} }
        },
      }),

      register: builder.mutation<{}, Req['register']>({
        query: (query: Req['register']) => ({
          body: query,
          method: 'post',
          url: `auth/signup`,
        }),
      }),

      updateProfile: builder.mutation<{}, Req['updateProfile']>({
        queryFn: async (query, { dispatch }, _2, baseQuery) => {
          const { avatar, file, ...profile } = query
          const body = {
            ...(profile || {}),
            date_of_birth: profile?.date_of_birth
              ? dayjs(profile?.date_of_birth).format('YYYY-MM-DD')
              : null,
          }
          const formData = toFormData(body)
          if (file) {
            formData.append('avatar', file as any)
          }
          Object
          const { data, error } = await baseQuery({
            body: formData,
            method: 'PUT',
            url: `users/me/`,
          })
          if (error?.data) {
            return { error: error?.data }
          }
          const res = (await dispatch(
            userService.endpoints.getUser.initiate({}, { forceRefetch: true }),
          )) as any
          return { data: res.data, error: res.error }
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export const useUser = userService.endpoints.getUser.useQueryState
export const {
  useGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useUpdateProfileMutation,
  // END OF EXPORTS
} = userService

export async function logout(
  store: any,
  data: Req['logout'],
  options?: Parameters<typeof userService.endpoints.logout.initiate>[1],
) {
  return store.dispatch(userService.endpoints.logout.initiate(data, options))
}

export async function login(
  store: any,
  data: Req['login'],
  options?: Parameters<typeof userService.endpoints.login.initiate>[1],
) {
  return store.dispatch(userService.endpoints.login.initiate(data, options))
}

export async function register(
  store: any,
  data: Req['register'],
  options?: Parameters<typeof userService.endpoints.register.initiate>[1],
) {
  return store.dispatch(userService.endpoints.register.initiate(data, options))
}

export async function updateProfile(
  store: any,
  data: Req['updateProfile'],
  options?: Parameters<typeof userService.endpoints.updateProfile.initiate>[1],
) {
  return store.dispatch(
    userService.endpoints.updateProfile.initiate(data, options),
  )
}

export async function getUser(
  store: any,
  data: Req['getUser'],
  options?: Parameters<typeof userService.endpoints.getUser.initiate>[1],
) {
  return store.dispatch(userService.endpoints.getUser.initiate(data, options))
}

// Call this on resolving login, if the login action returns a 401, treat it as an unconfirmed email
export const tryConfirmEmailRedirect = function (res: any) {
  if (res?.error?.status === 401) {
    cognito.getSession().then((session) => {
      const email = session.getIdToken().payload?.email
      getApi().confirmEmailRedirect(email)
    })
  }
}
