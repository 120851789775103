import React, { useEffect, useMemo, useRef, useState } from 'react'
import 'project/polyfill'
import { NextPageWithLayout } from 'types/nextPageWithLayout'
import Step from 'components/Step'
import Button from 'components/base/forms/Button'
import {
  MailItem,
  MailItemSummary,
  PagedResponse,
  SubscriptionSummary,
} from 'common/types/responses'
import MailActionTable from 'components/mail-actions/MailActionTable'
import Breadcrumb from 'components/base/Breadcrumb'
import { createForwardMailItems } from 'common/services/useForwardMailItem'
import { getStore } from 'common/store'
import { toast } from 'components/base/Toast'
import { getAddressData, printLabel } from 'components/ZPLItem'
import useActiveDeviceDetail from 'common/hooks/useActiveDeviceDetail'
import AsyncStorage from '@callstack/async-storage'
import { InfoMessage } from 'components/base/Messages'
import CompanyStatus from 'components/CompanyStatus'

export type HomePageType = {}

export const getAddressPartsForSubscription = function (
  subscription: SubscriptionSummary,
) {
  const address = subscription?.forwarding_address
  const contact = subscription?.contact_name
  const company = subscription?.name || subscription?.trading_name
  if (!address) {
    return []
  }
  return [].concat(address?.split(/\n/g) || []).filter((v) => !!v)
}

export const getAddressParts = function (
  mailItem: MailItem | MailItemSummary | null,
) {
  const address = mailItem?.forwarding_address
  const contact = mailItem?.subscription?.contact_name
  const company =
    mailItem?.subscription?.name || mailItem?.subscription?.trading_name
  if (!address) {
    return []
  }
  return [
    contact,
    company,
    address.address_line_1,
    address.address_line_2,
    address.address_line_3,
    address.city,
    address.country,
    address.postal_code,
    mailItem?.subscription?.plan?.toLowerCase()?.includes('international')
      ? 'International'
      : null,
  ].filter((v) => !!v)
}

const key = 'unprocessed-forwards'
const HomePage: NextPageWithLayout<HomePageType> = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const { writeData } = useActiveDeviceDetail()

  const printAddress = function (mailItem: MailItemSummary) {
    const parts = getAddressParts(mailItem)
    const prefix =
      mailItem.status === 'ON_HOLD_KYC_ID' ? [mailItem.location || 'NO ID'] : []
    if (writeData) {
      printLabel(
        writeData,
        getAddressData(prefix.concat(parts as string[])),
      ).finally(() => {})
    } else {
      toast('Error: you are not connected to a label printer', null, 'danger')
    }
  }
  const [mailItems, setMailItems] = useState<PagedResponse<MailItemSummary>>({
    count: 0,
    currentPage: 1,
    next: '',
    previous: '',
    results: [],
    totalPages: 1,
  })
  const [processed, setProcessed] = useState<MailItemSummary[]>([])

  const unprocessed = useMemo(() => {
    return mailItems?.results?.filter(
      (v) => !processed?.find((p) => p.id === v.id),
    )
  }, [processed, mailItems])
  const firstRender = useRef(true)
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }
    AsyncStorage.setItem(key, JSON.stringify(unprocessed))
  }, [unprocessed])
  useEffect(() => {
    AsyncStorage.getItem(key).then((v: string) => {
      if (v) {
        setMailItems({
          ...mailItems,
          results: JSON.parse(v),
        })
      }
    })
  }, [])
  const complete = async () => {
    if (!unprocessed?.length) {
      return
    }
    setLoading(true)
    const done: MailItemSummary[] = []
    for (let i = 0; i < unprocessed.length; i++) {
      const v = unprocessed[i]
      await createForwardMailItems(getStore(), { id: v.id }).then((res) => {
        if (!res?.error) {
          done.push(v)
        } else {
          setError(res.error)
        }
      })
      setProcessed((processed) => (processed || []).concat(done))
    }
    setLoading(false)
  }
  const noIdItems = mailItems?.results?.filter?.(
    (v) => v.status === 'ON_HOLD_KYC_ID',
  )
  return (
    <>
      <main className='px-9 pb-9'>
        <Breadcrumb
          links={[
            { text: 'Home', url: '/dashboard' },
            { text: 'Mail', url: '/mail' },
            { text: 'Forwards', url: '/mail/forwards' },
          ]}
        />
        <div className='row'>
          <div className='col-md-12 flex-row gap-8 my-4'>
            <Step
              title={'Process Mail - Forwards'}
              subtitle={
                'Collect mail items from each location and scan or type the barcode printed on the envelope'
              }
              currentValue={0}
              value={0}
            />
          </div>
          {!!noIdItems?.length && (
            <div>
              <InfoMessage className='alert alert-info mb-4'>
                <h3 className='mb-0'>
                  The following mail items are awaiting KYC checks
                </h3>
              </InfoMessage>
              {noIdItems.map((mailItem) => (
                <div className='alert border-bottom pb-2 alert-info mb-4'>
                  <strong className='fs-xs'>
                    <span className='fa fa-qrcode' /> {mailItem.barcode_id}
                  </strong>
                  <div className='flex-row gap-2 align-items-center'>
                    <div>{getAddressParts(mailItem)?.join(', ')} </div>
                    <CompanyStatus status={mailItem.subscription?.status} />
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className='col-md-12'>
            <MailActionTable
              error={error}
              hideCamera
              processed={processed}
              processedLabel='Processed'
              onScan={(mail) => {
                if (mail?.status === 'FORWARDED') {
                  toast(
                    'Error: mail is already marked as forwarded.',
                    null,
                    'danger',
                  )
                  return false
                } else if (!mail?.actions?.includes('FORWARD')) {
                  toast(
                    'Error: mail is not allowed to be forwarded, please check the customer plan and mail status.',
                    null,
                    'danger',
                  )
                  return false
                }
                if (mail?.forwarding_address) {
                  printAddress(mail)
                  return true
                } else {
                  toast(
                    'Error: associated subscription has no address data',
                    null,
                    'danger',
                  )
                  return false
                }
              }}
              items={mailItems}
              onItemsChanged={setMailItems}
              scanToAdd
              requiresScan
            >
              <div className='justify-content-end flex-row mt-4'>
                <Button
                  style={{ width: 150 }}
                  onClick={complete}
                  disabled={!unprocessed?.length || loading}
                  size='large'
                >
                  Complete
                </Button>
              </div>
            </MailActionTable>
          </div>
        </div>
      </main>
    </>
  )
}

export default HomePage
