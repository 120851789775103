import 'project/polyfill'
import { AppProps } from 'next/app'
import 'styles/Global.scss'
import { NextPageWithLayout } from 'types/nextPageWithLayout'
import NProgress from 'components/util/NProgress'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { nextReduxWrapper } from 'components/util/nextReduxWrapper'
import { ToastContainer } from 'components/base/Toast'
import ProfileContainer from 'components/util/ProfileContainer'
import { Project } from 'common/project'
import Nav from 'components/nav/Nav'
import { FlagsmithProvider } from 'flagsmith/react'
import flagsmith from 'flagsmith/isomorphic'
import DarkModeHandler from 'components/DarkModeHandler'
import { GoogleOAuthProvider } from '@react-oauth/google'
import AuthContainer from 'components/util/AuthContainer'
import 'react-quill/dist/quill.snow.css'

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}
export const defaultLayout: NextPageWithLayout['getLayout'] = (page) => {
  return (
    <>
      <div className='flex-fill'>
        <Nav />
        {page}
      </div>
    </>
  )
}
const AppComponent: FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout || defaultLayout
  const router = useRouter()
  if (router.asPath.includes('sw.js')) {
    return null
  }
  return (
    <GoogleOAuthProvider clientId={Project.google.clientID}>
      <FlagsmithProvider
        flagsmith={flagsmith}
        options={{
          enableAnalytics: true,
          environmentID: Project.flagsmith,
          preventFetch: true,
        }}
      >
        <Head>
          <meta charSet='utf-8' />
          <meta httpEquiv='x-ua-compatible' content='ie=edge' />
          <meta name='theme-color' content='#317EFB' />
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1, shrink-to-fit=no'
          />
          <link rel='apple-touch-icon' href='/images/icons-192.png' />
          <link rel='icon' sizes='192x192' href='/images/icons-192.png' />
          <link rel='shortcut icon' href='/images/favicon.ico' />
          <link
            rel='icon'
            type='image/png'
            sizes='32x32'
            href='/images/favicon-32x32.png'
          />
          <link
            rel='icon'
            type='image/png'
            sizes='16x16'
            href='/images/favicon-16x16.png'
          />
          <meta property='og:title' content='Operations Admin' />
          {/*<script>window.printerAddress = 'http://127.0.0.1:9100/'</script>*/}
          <script src={'/scripts/BrowserPrint-3.1.250.min.js'} />
          <script src={'/scripts/BrowserPrint-Zebra-1.1.250.min.js'} />

          <title>Operations Admin</title>
        </Head>
        <NProgress />
        <ProfileContainer>
          <AuthContainer>
            {(user: any) =>
              getLayout(<Component {...pageProps} />, pageProps, user)
            }
          </AuthContainer>
        </ProfileContainer>
        <ToastContainer />
        <div id='modal' />
        <div id='confirm' />
        <div id='alert' />
        <DarkModeHandler />
      </FlagsmithProvider>
    </GoogleOAuthProvider>
  )
}

export default nextReduxWrapper.withRedux(AppComponent)
