import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { toParam } from 'common/utils/urls/param'
import toFormData from 'common/toFormData'
import { companyService } from './useCompany'
import { getStore } from 'common/store'
import transformPagination from 'common/utils/transformPagination'

export const subscriptionService = service
  .enhanceEndpoints({ addTagTypes: ['Subscription'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createSubscriptionDocument: builder.mutation<
        Res['createSubscriptionDocument'],
        Req['createSubscriptionDocument']
      >({
        query: (query) => {
          const formData = toFormData({})
          formData.append('file', query.file as any)
          formData.append('description', query.description)
          return {
            body: formData,
            method: 'POST',
            url: `companies/${query.company_id}/subscriptions/${query.subscription_id}/documents`,
          }
        },
        transformResponse: (res, _, query) => {
          getStore().dispatch(
            companyService.util.invalidateTags([
              { id: query.company_id, type: 'Company' },
            ]),
          )
          return res
        },
      }),
      getSubscription: builder.query<
        Res['subscription'],
        Req['getSubscription']
      >({
        providesTags: (res) => [{ id: res?.id, type: 'Subscription' }],
        query: (query: Req['getSubscription']) => ({
          url: `companies/${query.company_id}/subscriptions/${query.id}`,
        }),
        transformResponse: (res, _, req) => {
          return {
            ...res,
            ...res?.subscriptions.find((v) => `${v.id}` === `${req.id}`),
            subscriptions: undefined,
          }
        },
      }),
      getSubscriptions: builder.query<
        Res['subscriptions'],
        Req['getSubscriptions']
      >({
        providesTags: [{ id: 'LIST', type: 'Subscription' }],
        query: (q) => ({
          url: `subscriptions?${toParam(q)}`,
        }),
      }),
      getSubscriptionsList: builder.query<
        Res['getSubscriptionsList'],
        Req['getSubscriptionsList']
      >({
        query: (q) => ({
          url: `subscriptions/list?${toParam(q)}`,
        }),
        transformResponse: (res, _, req) => {
          return transformPagination(req, res)
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getSubscriptions(
  store: any,
  data: Req['getSubscriptions'],
  options?: Parameters<
    typeof subscriptionService.endpoints.getSubscriptions.initiate
  >[1],
) {
  return store.dispatch(
    subscriptionService.endpoints.getSubscriptions.initiate(data, options),
  )
}
export async function getSubscription(
  store: any,
  data: Req['getSubscription'],
  options?: Parameters<
    typeof subscriptionService.endpoints.getSubscription.initiate
  >[1],
) {
  return store.dispatch(
    subscriptionService.endpoints.getSubscription.initiate(data, options),
  )
}

export async function createSubscriptionDocument(
  store: any,
  data: Req['createSubscriptionDocument'],
) {
  return store.dispatch(
    subscriptionService.endpoints.createSubscriptionDocument.initiate(data),
  )
}

export async function getSubscriptionsList(
  store: any,
  data: Req['getSubscriptionsList'],
) {
  return store.dispatch(
    subscriptionService.endpoints.getSubscriptionsList.initiate(data),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useCreateSubscriptionDocumentMutation,
  useGetSubscriptionQuery,
  useGetSubscriptionsListQuery,
  useGetSubscriptionsQuery,
  // END OF EXPORTS
} = subscriptionService

/* Usage examples:
const { data, isLoading } = useGetSubscriptionsQuery({ id: 2 }, {}) //get hook
const [createSubscriptions, { isLoading, data, isSuccess }] = useCreateSubscriptionsMutation() //create hook
subscriptionService.endpoints.getSubscriptions.select({id: 2})(store.getState()) //access data from any function
*/
