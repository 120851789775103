import { BatchSummary, PagedResponse, Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { toParam } from 'common/utils/urls/param'
import { groupBy, sortBy } from 'lodash'
import dayjs from 'dayjs'
import { responses } from 'common/types/mock-data'

export const batchService = service
  .enhanceEndpoints({ addTagTypes: ['Batch'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createBatch: builder.mutation<Res['batch'], Req['createBatch']>({
        invalidatesTags: [{ id: 'LIST', type: 'Batch' }],
        query: (query: Req['createBatch']) => ({
          body: query,
          method: 'POST',
          url: `operations/batches`,
        }),
        // queryFn: () => ({ data: responses.batch }),
      }),
      getBatch: builder.query<Res['batch'], Req['getBatch']>({
        providesTags: (res) => [{ id: res?.id, type: 'Batch' }],
        query: (query: Req['getBatch']) => ({
          url: `operations/batches/${query.id}`,
        }),
        // queryFn: (query: Req['getBatch']) => ({
        //   data: responses.batch,
        // }),
      }),
      getBatches: builder.query<Res['batches'], Req['getBatches']>({
        providesTags: [{ id: 'LIST', type: 'Batch' }],
        query: (query: Req['getBatches']) => ({
          url: `operations/batches?${toParam({
            ...query,
            page_size: 100,
            pass_number: query.pass_number || 1,
          })}`,
        }),
        transformResponse: (res: PagedResponse<BatchSummary>) => {
          const groups = groupBy(res.results, (res) =>
            dayjs(res.created_at).format('YYYY-MM-DD'),
          )
          const ret = {
            ...res,
            results: sortBy(
              Object.keys(groups)
                .map((v) => ({
                  batches: groups[v].map((v) => ({
                    ...v,
                    awaiting_action_count: v.awaiting_action_count || 0,
                    matched_count: v.matched_count || 0,
                    no_match_count: v.no_match_count || 0,
                    process_later_count: v.process_later_count || 0,
                    rejected_count: v.rejected_count || 0,
                  })),
                  date: v,
                }))
                .filter((v) => !!v.batches?.length),
              (v: Res['batches']['results'][number]) =>
                -dayjs(v.date).valueOf(),
            ),
          }
          return ret
        },
        // queryFn: () => {
        //   return {
        //     data: {
        //       ...responses.batches,
        //       results: responses.batches.results.filter(
        //         (v) => !!v.batches?.length,
        //       ),
        //     },
        //   }
        // },
      }),
      sortBatchItems: builder.mutation<Res['batch'], Req['sortBatchItems']>({
        invalidatesTags: (res, err, arg) => [
          { id: 'LIST', type: 'Batch' },
          { id: arg?.id, type: 'Batch' },
        ],
        query: (query: Req['sortBatchItems']) => ({
          body: query,
          method: 'POST',
          url: `operations/batches/${query.id}/actions/sorted`,
        }),
      }),
      updateBatch: builder.mutation<Res['batch'], Req['updateBatch']>({
        invalidatesTags: (res) => [
          { id: 'LIST', type: 'Batch' },
          { id: res?.id, type: 'Batch' },
        ],
        //used just for close batch
        query: (query: Req['updateBatch']) => ({
          body: {},
          method: 'POST',
          url: `operations/batches/${query.id}/actions/close`,
        }),
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getBatches(
  store: any,
  data: Req['getBatches'],
  options?: Parameters<typeof batchService.endpoints.getBatches.initiate>[1],
) {
  return store.dispatch(
    batchService.endpoints.getBatches.initiate(data, options),
  )
}
export async function getBatch(
  store: any,
  data: Req['getBatch'],
  options?: Parameters<typeof batchService.endpoints.getBatch.initiate>[1],
) {
  return store.dispatch(batchService.endpoints.getBatch.initiate(data, options))
}
export async function createBatch(
  store: any,
  data: Req['createBatch'],
  options?: Parameters<typeof batchService.endpoints.createBatch.initiate>[1],
) {
  return store.dispatch(
    batchService.endpoints.createBatch.initiate(data, options),
  )
}
export async function updateBatch(
  store: any,
  data: Req['updateBatch'],
  options?: Parameters<typeof batchService.endpoints.updateBatch.initiate>[1],
) {
  return store.dispatch(
    batchService.endpoints.updateBatch.initiate(data, options),
  )
}
export async function sortBatchItems(
  store: any,
  data: Req['sortBatchItems'],
  options?: Parameters<
    typeof batchService.endpoints.sortBatchItems.initiate
  >[1],
) {
  return store.dispatch(
    batchService.endpoints.sortBatchItems.initiate(data, options),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useCreateBatchMutation,
  useGetBatchQuery,
  useGetBatchesQuery,
  useSortBatchItemsMutation,
  useUpdateBatchMutation,
  // END OF EXPORTS
} = batchService

/* Usage examples:
const { data, isLoading } = useGetBatchesQuery({ id: 2 }, {}) //get hook
const [createBatches, { isLoading, data, isSuccess }] = useCreateBatchesMutation() //create hook
batchService.endpoints.getBatches.select({id: 2})(store.getState()) //access data from any function
*/
